import React from "react"
import Base from "./Base"
import Banner from "../organisms/banner/Banner"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"
import ComponentParser from "../particles/ComponentParser"
import Content from "../particles/Content"
import styled from "@emotion/styled"
import SubHeading from "../atoms/SubHeading/SubHeading"
import theme from "../particles/Theme"
import SecondaryButton from "../atoms/Buttons/SecondaryButton/SecondaryButton"
import Sidebar from "../molecules/Sidebar/Sidebar"

const StyledDiv = styled.div`
  margin: 5rem 0;
  display: grid;
  grid-template-columns: auto 360px;
  grid-gap: 3rem;

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: 1fr;
  }

  .blog-content {
    padding-right: 5rem;
  }
`

const Section = styled.section`
  h4 {
    margin-top: 3rem;
    margin-bottom: 5rem;

    @media (max-width: ${theme.breakpoints[1]}) {
      text-align: center;
    }
  }
`

const Page = ({ pageContext }) => {
  return (
    <Base context={pageContext} title={pageContext.title}>
      <Banner title={pageContext.title} />
      <Breadcrumbs />
      <Content>
        <StyledDiv>
          <Section>
            <SubHeading title={pageContext.title} />
            <h4>{pageContext.author}</h4>
            <ComponentParser content={pageContext.blocks} />
            <section style={{ marginTop: `1.5rem` }}>&nbsp;</section>
            <SecondaryButton url="/blogs">Back to Blogs</SecondaryButton>
          </Section>
          <div>
            <Sidebar />
          </div>

        </StyledDiv>
      </Content>
    </Base>
  )
}

export default Page
